<template>
  <div>
    <MiniBanner />
    <div class="txt txt1">
      <p></p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: #c10000"
          ><strong>会议时间：</strong></span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="
            color: rgb(0, 0, 0);

            font-size: 14px;
          "
          >2021年07月23-25日</span
        >
      </p>
      <p><br /></p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: #c10000"
          ><strong>开幕时间：</strong></span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="
            color: rgb(0, 0, 0);

            font-size: 14px;
          "
          >2021年07月24日</span
        >
      </p>
      <p><br /></p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: #c10000"
          ><strong>会议形式：</strong></span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="
            color: rgb(0, 0, 0);

            font-size: 14px;
          "
          >7月23日 线上会议直播<br />
          7月24-25日 线下会议（线上同步直播）</span
        >
      </p>
      <p style="line-height: 2em">
        <span style="color: rgb(0, 0, 0); font-size: 14px">&nbsp;</span>
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: #c10000"
          ><strong>会议地点：</strong></span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="
            color: rgb(0, 0, 0);

            font-size: 14px;
          "
          >北京国际饭店会议中心</span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="
            box-sizing: border-box;
            color: rgb(84, 141, 212);
            font-size: 14px;
          "
          ><strong
            ><br /><span
              style="font-size: 14px; box-sizing: border-box; color: #c10000"
              >北京医学会</span
            ></strong
          ></span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="box-sizing: border-box; font-size: 14px; color: rgb(0, 0, 0)"
          >于银林&nbsp;</span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="
            background-color: rgb(255, 255, 255);
            font-size: 14px;
            color: rgb(0, 0, 0);
          "
          >固话：010-65252365</span
        >
      </p>

      <p style="line-height: 2em">
        <span style="color: #c10000; font-size: 14px"
          ><span style="font-size: 14px"><br /></span
          ><strong><span style="color: #c10000">注册联系人</span></strong></span
        >
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)">刘欣竹</span>
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >固话：010-58138086<br
        /></span>
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >手机：19910216241</span
        >
      </p>

      <p style="line-height: 2em">
        <span style="font-size: 14px"
          ><span style="font-size: 14px"><br /></span
          ><span style="font-size: 14px; color: #c10000"
            ><strong>企业联系人</strong></span
          ></span
        >
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)">黄小慧&nbsp;</span>
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >手机：15801500248</span
        >
      </p>

      <p
        style="
          white-space: normal;
          box-sizing: border-box;
          margin: 0px;
          padding: 0px;
          color: rgb(121, 121, 121);
          font-size: 16px;
          background-color: rgb(255, 255, 255);
          line-height: 2em;
        "
      >
        <span
          style="box-sizing: border-box; font-size: 14px; color: rgb(0, 0, 0)"
          ><br
        /></span>
      </p>
    </div>
  </div>
</template>

<script>
import MiniBanner from "@/components/miniBanner/index";
import { mapActions } from "vuex";

export default {
  name: "MiniBasicInfo",
  components: {
    MiniBanner,
  },
  methods: {
    ...mapActions({
      setTitle: "setTitleFunc",
    }),
  },
  created() {
    this.setTitle("会议信息");
  },
};
</script>

<style scoped>
.title {
  border-bottom: 1px solid #e5f3fc;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}
.title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.title > h4:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
p {
  margin: 0;
}
.txt {
  font-size: 16px;
  color: #797979;
  line-height: 1.5em;
  padding: 25px 30px;
}
</style>